<template lang="pug">
div
  loading-logo(v-if="loading")
  div(v-show="!loading")
    slot(slot="error")
      .mx-4.alert.alert-primary(v-if="paymentError")
        span(v-if="paymentError.type === 'CUSTOMER'") {{ $t('payment.error.customer-payment-error', { message: paymentError.message }) }}
        span(v-else-if="paymentError.message") {{ $t('payment.error.customer-payment-error', { message: paymentError.message }) }}
        span(v-else) {{ paymentError }}
    .customer-data-content(v-show="showBankTransfer")
      .p-2.brand-payment-card
        input#bank_transfer.mr-2(
          type="radio"
          name="payment-method"
          value="bank_transfer"
          v-model="selPayment"
        )
        label(for="bank_transfer") {{ $t('payment.banktransferWithPrice') }}
      .p-2.brand-payment-card
        input#braintree.mr-2(
          type="radio"
          name="payment-method"
          value="braintree"
          v-model="selPayment"
        )
        label(for="braintree") {{ $t('payment.braintree') }}
    div(v-show="isBraintreeSelected || !showBankTransfer")
      .d-flex.customer-data-content
        #payment-container.col-12
    .d-flex.customer-data-content(v-if="showButton")
      om-button#save-payment.mt-2(primary @click.prevent="savePaymentMethod") {{ $t('save') }}
      om-button.mx-2.mt-2(
        secondary
        v-show="deletablePayments && hasInactivePaymentMethod"
        @click="$modal.show('delete-payment-methods')"
      ) {{ $t('payment.deleteInactiveCards') }}
    delete-payment-methods-modal(@deleted="loadPaymentMethods")
</template>

<script>
  import GET_BRAINTREE_CLIENT_TOKEN from '@/graphql/GetBraintreeClientToken.gql';
  import GET_INACTIVE_PAYMENT_METHODS from '@/graphql/GetInactivePaymentMethods.gql';
  import { get as _get } from 'lodash-es';
  import { mapGetters, createNamespacedHelpers } from 'vuex';
  import { toASCIIString } from '@/util';
  import DeletePaymentMethodsModal from '@/components/Modals/DeletePaymentMethods';
  import { isNewPaymentEnabled } from '@/utils/features';

  const { mapState } = createNamespacedHelpers('payment');

  export default {
    name: 'PaymentChoose',
    components: { DeletePaymentMethodsModal },
    props: {
      region: { type: String, required: true },
      billing: {
        type: Object,
        default: () => {
          return {};
        },
      },
      locale: { type: String, default: 'en' },
      currentPaymentMethod: { type: String, default: '' },
      selectedPayment: { type: String, required: true },
      price: { type: Number, required: true },
      deletablePayments: { type: Boolean, default: false },
      showButton: { type: Boolean, default: true },
      period: { type: Number, required: false },
    },
    data() {
      return {
        loading: true,
        paymentError: null,
        selPayment: '',
        teardownBraintree: null,
        requestPaymentMethod() {
          return Promise.resolve();
        },
        hasInactivePaymentMethod: false,
      };
    },

    computed: {
      ...mapState(['selectedPeriod']),
      ...mapGetters(['getRegion', 'currentPeriod', 'accountFeatures']),
      showBankTransfer() {
        return (
          this.getRegion === 'Hungary' &&
          ((this.getPeriod === 12 && !this.hasPaymentMethod) ||
            this.currentPaymentMethod === 'bank_transfer')
        );
      },
      isBraintreeSelected() {
        return this.selPayment.indexOf('braintree') === 0;
      },
      hasPaymentMethod() {
        return this.currentPaymentMethod !== 'none' && this.currentPaymentMethod !== '';
      },
      getPeriod() {
        return this.period || this.selectedPeriod || this.currentPeriod;
      },
    },

    created() {
      this.selPayment = this.selectedPayment;
    },

    mounted() {
      this.loadPaymentMethods();

      if (!this.selPayment && this.currentPaymentMethod) {
        this.selPayment = this.currentPaymentMethod;
      }

      if (!this.hasPaymentMethod) {
        this.selPayment = 'braintree';
      }
    },

    methods: {
      ...mapGetters(['getAccountEmail']),

      setPaymentError(error) {
        this.paymentError = error;
      },
      loadPaymentMethods() {
        this.hasInactivePaymentMethod = false;
        this.$apollo
          .query({
            query: GET_BRAINTREE_CLIENT_TOKEN,
          })
          .then(
            ({
              data: {
                token: { token },
              },
            }) => {
              this.preparePayments(token);
            },
          );
      },

      preparePayments(token) {
        const _self = this;

        return new Promise(function (resolve, reject) {
          let tearProm = Promise.resolve();
          if (_self.teardownBraintree) {
            tearProm = _self.teardownBraintree();
          }

          tearProm.then(async function () {
            const { default: dropin } = await import('braintree-web-drop-in');
            dropin.create(
              {
                authorization: token,
                translations: _self.locale === 'hu' ? _self.$t('braintree') : {},
                paypal: {
                  flow: 'vault',
                },
                threeDSecure: true,
                container: '#payment-container',
              },
              function (createErr, instance) {
                if (createErr) {
                  return reject(createErr);
                }

                instance.on('paymentMethodRequestable', function () {
                  _self.loaded();
                });

                instance.on('noPaymentMethodRequestable', function () {
                  _self.$emit('noPaymentMethodRequestable');
                });

                resolve(instance);
              },
            );
          });
        })
          .then(async function (btInstance) {
            _self.teardownBraintree = function () {
              return btInstance.teardown();
            };
            _self.loaded();

            if (!btInstance.isPaymentMethodRequestable()) {
              _self.$emit('noPaymentMethodRequestable');
            }

            if (_self.isBraintreeSelected) {
              _self.$emit('payload', { payload: btInstance._mainView.model._activePaymentMethod });
            }

            if (_self.deletablePayments) {
              _self.collectInactivePaymentMethods(btInstance);
            }

            btInstance.on('noPaymentMethodRequestable', function () {
              _self.$emit('noPaymentMethodRequestable');
            });

            btInstance.on('paymentOptionSelected', function () {
              _self.setPaymentError(null);
            });

            _self.requestPaymentMethod = function () {
              if (_self.selPayment === 'bank_transfer' || _self.selPayment === 'shopify') {
                _self.$emit('payload', { payload: null });
                return Promise.resolve();
              }

              const amount =
                _self.price % 1 === 0 ? _self.price : parseFloat(_self.price).toFixed(2);
              const threeDSParams = {
                amount,
                email: _self.getAccountEmail,
              };

              const address = _self.billing.address;
              if (address) {
                threeDSParams.billingAddress = {
                  givenName: toASCIIString(_self.billing.firstName),
                  surname: toASCIIString(_self.billing.lastName),
                  postalCode: _self.billing.postalCode,
                };
                // Braintree only allows max 50 characters
                if (address.length <= 50) {
                  threeDSParams.billingAddress.streetAddress = address;
                }
              }

              return new Promise(function (resolve, reject) {
                btInstance.requestPaymentMethod(
                  { threeDSecure: threeDSParams },
                  function (requestPaymentMethodErr, payload) {
                    if (requestPaymentMethodErr) {
                      return reject(requestPaymentMethodErr);
                    }

                    resolve(payload);
                  },
                );
              });
            };
          })
          .catch((error) => {
            console.error(error);
            if (error.name === 'DropinError') return;

            _self.loadPaymentMethods();
          });
      },

      savePaymentMethod() {
        this.loading = true;
        const _self = this;
        this.requestPaymentMethod()
          .then((payload) => {
            const isBraintree = _self.isBraintreeSelected;
            let method;
            if (isBraintree) {
              if (!payload.nonce || (!payload.liabilityShifted && payload.liabilityShiftPossible)) {
                // 3D Secure authentication failed
                const err = new Error('3D Secure authentication failed');
                err.name = 'BraintreeError'; // fake BraintreeError
                err.type = 'CUSTOMER';

                this.loadPaymentMethods();
                throw err;
              }
              method = payload.type === 'CreditCard' ? 'braintree' : 'braintreepaypal';
            } else {
              method = this.selPayment;
            }

            _self.setPaymentError(null);
            _self.loaded();
            this.$emit('save', { method, payload });
          })
          .catch((e) => {
            console.error(e);
            _self.setPaymentError(e);

            _self.loaded();
          });
      },
      isNewPayment() {
        return isNewPaymentEnabled(this.accountFeatures);
      },
      getInactivePaymentMethods(payments) {
        if (payments.length === 1) {
          return false;
        }

        let hasInactivePaymentMethod = this.hasInactivePaymentMethod;
        payments.forEach((payment) => {
          if (!this.isNewPayment()) {
            hasInactivePaymentMethod =
              !hasInactivePaymentMethod && payment.vaulted && !payment.hasSubscription;
          } else {
            hasInactivePaymentMethod = !hasInactivePaymentMethod && !payment.default;
          }
        });

        return hasInactivePaymentMethod;
      },
      async collectInactivePaymentMethods(btInstance) {
        const payments = _get(btInstance, '_mainView.model._paymentMethods', []);
        this.hasInactivePaymentMethod = this.getInactivePaymentMethods(payments);
        if (!this.hasInactivePaymentMethod) {
          try {
            const {
              data: { payments },
            } = await this.$apollo.query({
              query: GET_INACTIVE_PAYMENT_METHODS,
            });

            this.hasInactivePaymentMethod = !!payments.length;
          } catch (e) {
            console.log(e);
          }
        }
      },

      resetBraintree() {
        this.loadPaymentMethods();
      },
      loaded() {
        this.loading = false;
        this.$emit('loaded');
      },
    },
  };
</script>

<style lang="sass" scoped>
  #payment-container
    max-width: 750px
</style>

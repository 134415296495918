<template lang="pug">
om-modal(name="delete-payment-methods" :width="845" :scrollable="true" @beforeOpen="beforeOpen")
  template(slot="modal-header")
    .row
      .col
        .font-weight-bold.font-size-1--25 {{ $t('payment.deleteInactiveCards') }}
    .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('delete-payment-methods')")
      close-icon(:width="16" :height="16")
  template(slot="modal-body")
    loading-logo(v-if="loading")
    div(v-show="!loading")
      .d-flex.customer-data-content.payment-list-item(v-for="(payment, index) in inactivePayments")
        .row.align-items-center
          input.mx-2(
            type="checkbox"
            :value="payment.token"
            :id="`payment-${index}`"
            v-model="deleteTokens"
          )
          label.d-flex(:for="`payment-${index}`")
            img.mx-2(
              v-if="payment.type === 'paypal'"
              :src="require('@/assets/admin/svg/payment/paypal.svg')"
            )
            img.mx-2(
              v-else
              :src="require(`@/assets/admin/svg/payment/${normalizePaymentName(payment.cardType)}.svg`)"
            )
            .mx-2(v-if="payment.type === 'paypal'")
              .list-item-title {{ payment.email }}
              div PayPal
            .mx-2(v-else)
              .list-item-title {{ payment.maskedNumber }}
              div {{ payment.cardType }}
  template(slot="modal-footer")
    .d-flex
      om-button(primary @click="$modal.hide('delete-payment-methods')") {{ $t('cancel') }}
      om-button.mx-2(secondary :disabled="!deleteTokens.length" @click="deletePayments") {{ $t('delete') }}
</template>

<script>
  import DELETE_PAYMENT_METHODS from '@/graphql/DeletePaymentMethods.gql';
  import GET_INACTIVE_PAYMENT_METHODS from '@/graphql/GetInactivePaymentMethods.gql';

  export default {
    name: 'DeletePaymentMethods',
    data() {
      return {
        loading: true,
        inactivePayments: [],
        deleteTokens: [],
      };
    },
    methods: {
      beforeOpen() {
        this.loadInactivePayments();
      },
      async loadInactivePayments() {
        this.inactivePayments = [];
        this.loading = true;

        const {
          data: { payments },
        } = await this.$apollo.query({
          query: GET_INACTIVE_PAYMENT_METHODS,
        });

        this.loading = false;

        this.inactivePayments = payments;
      },

      normalizePaymentName(cardType) {
        cardType = cardType.replace(' ', '');
        return cardType.charAt(0).toLowerCase() + cardType.substr(1);
      },

      async deletePayments() {
        if (!this.deleteTokens.length) {
          return;
        }

        const tokens = this.deleteTokens;
        const result = await this.$apollo.mutate({
          mutation: DELETE_PAYMENT_METHODS,
          variables: {
            tokens,
          },
        });

        if (result) {
          this.inactivePayments = this.inactivePayments.filter(
            (payment) => tokens.indexOf(payment.token) === -1,
          );
          this.$notify({
            type: 'success',
            text: this.$t('payment.deleteSuccess'),
          });
          this.$emit('deleted');
          if (!this.inactivePayments.length) {
            this.$modal.hide('delete-payment-methods');
          }
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('payment.deleteFailed'),
          });
        }
      },
    },
  };
</script>
